import { defineStore } from 'pinia'

export const use_calendar = defineStore('calendar', {
  state: () => ({
    selected_time_range: {
      start: null,
      end: null,
      is_all_day: null,
    },

    highlight_timeout_id: null,
    highlighted_recurring_task_group_id: -1,
  }),

  actions: {
    reset_selected_time_range() {
      this.selected_time_range = {
        start: null,
        end: null,
        is_all_day: null,
      }
    },
  },
})
