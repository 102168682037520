import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_services } from './services_store'
import { use_smcb_gym } from './smcb_gym_store'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

async function fetch_calls_page_from_ak(url, from, to, page, token) {
  const payload = {
    from_dt: from,
    to_dt: to,
    per_page: 50,
    page: page,
  }
  // WARNING : 'page' is not working here, so this will return bad date if more than 50 calls exist
  const headers = { Authorization: `Bearer ${token}` }
  const { data } = await axios.get(url, { headers: headers, params: payload })
  return data
}

function slim_down_call_statuses(calls) {
  return calls.map(call => {
    return slim_down_call_status(call)
  })
}

function slim_down_call_status(call) {
  const users = call.users
    .filter(u => u.tan && u.tan.length == 14)
    .map(u => {
      return { id: u.id, status: u.status }
    })

  return { call_id: call.id, users: users }
}

export const use_video_consultation = defineStore('video_consultation', {
  state: () => ({
    ak_token: null,
    ak_user_id: null,
    ak_email: null,
    ak_call_statuses: [],
    ak_video_base_url: null,
    ak_broadcaster: null,
    echo: null,
    auth_callbacks_queue: [],
    account_exists_for_location_id: null,
  }),

  actions: {
    async init() {
      if (use_services().of_selected?.settings?.uses_video_consultation) {
        this.fetch_ak_creds()
      }
    },

    async fetch_ak_creds() {
      const base_url = use_services().base_url_of_selected
      const url = `${base_url}/checkins/video-consultation/ak-credentials`
      const { data } = await Vue.smcb_axios.get(url)
      this.ak_video_base_url = data.base_url
    },

    async auth_callback(data) {
      this.ak_token = data.token
      this.ak_user_id = data.user_id
      this.ak_email = data.email
      this.ak_video_base_url = data.base_url

      const callback = this.auth_callbacks_queue.shift()

      if (callback && callback.fn) {
        callback.fn(callback.params)
      }
    },

    async do_with_ak_creds(fn, params, retry = true) {
      try {
        if (!this.ak_video_base_url || !this.ak_token) {
          console.warn('Fetching ak credentials first')
          this.auth_callbacks_queue.push({ fn: fn, params: params })
          this.fetch_ak_creds()
        } else {
          fn(params)
        }
      } catch (e) {
        // retry once, with an auth refresh
        console.error(e)
        this.ak_token = null
        if (retry) this.do_with_ak_creds(fn, params, false)
      }
    },

    async fetch_waiting_participants(date) {
      if (!date || !date.ts) {
        console.error('No valid date provided')
        return
      }
      this.do_with_ak_creds(this.do_fetch_waiting_participants, date)
    },

    async do_fetch_waiting_participants(date) {
      const from = Math.round(date.ts / 1000)
      const to = from + 24 * 60 * 60

      const url = `${this.ak_video_base_url}/api/v2/calls`

      let statuses = []
      let page = 1
      let last_page = 1
      do {
        const response = await fetch_calls_page_from_ak(url, from, to, page, this.ak_token)
        const calls = slim_down_call_statuses(response.data)
        this.ak_call_statuses = this.ak_call_statuses.concat(calls)
        page++
        last_page = response.meta.last_page //NQ-3101 is this ok: .meta??
      } while (page <= last_page)

      this.register_to_ak_ws()
    },

    async do_fetch_ak_ws_info() {
      const headers = { Authorization: `Bearer ${this.ak_token}` }
      const url = `${this.ak_video_base_url}/api/v2/serverinfo`

      const { data } = await Vue.smcb_axios.post(url, { email: this.ak_email }, { headers: headers })
      this.ak_broadcaster = data.broadcaster

      if (!this.echo) {
        this.echo = new Echo({
          broadcaster: this.ak_broadcaster.name,
          key: this.ak_broadcaster.key,
          statsHost: null,
          wsHost: this.ak_broadcaster.wshost,
          wsPort: this.ak_broadcaster.wsport,
          wssPort: this.ak_broadcaster.wssport,
          wsPath: this.ak_broadcaster.wspath,
          enabledTransports: ['ws', 'wss'],
          authEndpoint: `https://${this.ak_broadcaster.wshost}/broadcasting/auth`,
          cluster: 'eu',
          encrypted: true,
          auth: {
            headers: {
              Authorization: `Bearer `,
              Accept: 'application/json',
            },
          },
        })
      }

      this.echo.options.auth.headers['Authorization'] = `Bearer ${this.ak_token}`
    },

    async do_register_to_ak_ws() {
      await this.do_fetch_ak_ws_info()

      this.echo.join(`App.User.${this.ak_user_id}`).listen('Call\\CallUpdated', async e => {
        const updated_call = slim_down_call_status(e)

        const prev = this.ak_call_statuses.find(c => (c.call_id = updated_call.call_id))
        if (prev) prev.users = updated_call.users
        else this.ak_call_statuses.push(updated_call)
      })

      this.echo.join(`App.User.${this.ak_user_id}`).listen('User\\TanUserIsOnline', async e => {
        // only act if user is a tan user
        if (!e.tan || e.tan.length != 14) return

        // Maybe a rework of ak_call_statuses would improve efficiency
        this.set_status_for_user(e.id, 6001)
      })

      const waitingroom = this.echo.join(`App.Waitingroom.User.${this.ak_user_id}`)
      waitingroom.joining(e => {
        this.set_status_for_user(e.id, 6001)
      })
      waitingroom.leaving(e => {
        this.set_status_for_user(e.id, 5999)
      })
      waitingroom.here(e => {
        e.forEach(user => {
          if (user.tan && user.tan.length === 14) this.set_status_for_user(user.id, 6001)
        })
      })
    },

    set_status_for_user(id, status) {
      this.ak_call_statuses.forEach(c => {
        const u = c.users.find(u => u.id === id)
        if (u) u.status = status
      })
    },

    async register_to_ak_ws() {
      this.do_with_ak_creds(this.do_register_to_ak_ws, null)
    },

    async check_if_employee_has_account(employee, login_email) {
      // call to wilma, because this requires the master token
      const base_url = use_smcb_gym().base_url
      const url = `${base_url}/employees/${employee.id}/probe_ak_account?login_email=${login_email}`
      Vue.smcb_axios.get(url)
    },
  },

  getters: {
    gym_can_have_video_consultations() {
      const smcb_gym = use_smcb_gym()
      return this.enabled && (this.tsys_configured || this.ak_configured)
    },

    enabled() {
      const smcb_gym = use_smcb_gym()
      return !!smcb_gym.gym_options?.video_consultation_enabled && (!!smcb_gym.gym_options?.can_use_tsystems_video || !!smcb_gym.gym_options?.can_use_ak_video)
    },

    tsys_configured() {
      const smcb_gym = use_smcb_gym()
      return !!smcb_gym.gym_options?.can_use_tsystems_video && smcb_gym.settings.video_consultation_provider === 'tsys'
    },

    ak_configured() {
      const smcb_gym = use_smcb_gym()
      return !!smcb_gym.gym_options?.can_use_ak_video && smcb_gym.settings.video_consultation_provider === 'ak' && !!smcb_gym.settings.ak_company
    },

    default_provider() {
      const smcb_gym = use_smcb_gym()
      if (smcb_gym.gym_options.can_use_tsystems_video && !smcb_gym.gym_options.can_use_ak_video) {
        return 'tsys'
      }
      return 'ak'
    },
  },
})
