import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_entry_point } from './entry_point_store'
import { use_gym_modules } from './gym_modules_store'
import { use_services } from './services_store'
import { use_smcb_gym } from './smcb_gym_store'

function parse_participant(participant) {
  participant.checked_in_at = Vue.$vl_time.parse_as_local(participant.checked_in_at)
  participant.must_exit_at = Vue.$vl_time.parse_as_local(participant.must_exit_at)
  participant.checked_out_at = Vue.$vl_time.parse_as_local(participant.checked_out_at)
  return participant
}

export const use_visitors = defineStore('visitors', {
  state: () => ({
    loading: true,
    current_day: null,
    integration_data: {},
    is_first_load: true,
    participants_by_id: {},
    participants_to_load: {},
    monthly_summary: {},
    demis_fails: [],
    search_results: {},
    selected: null,
    hide_barcodes: true,
    ws_fetching_queue: null,
    export_files: null,
  }),

  actions: {
    // Helper method used while loading the page call to diff a participant
    load_participant_if_needed(p, participants_to_load) {
      const existing = this.participants_by_id[p.id]

      if (!existing) {
        if (p.u) {
          Vue.set(this.participants_by_id, p.id, p)
          participants_to_load[p.id] = true
        } else {
          Vue.set(this.participants_by_id, p.id, parse_participant(p))
        }
      } else if (existing.up_at != p.up_at) {
        console.log('VL P!', p.id)
        if (p.u) {
          participants_to_load[p.id] = true
        } else {
          Vue.set(this.participants_by_id, p.id, parse_participant(p))
        }
      } else if (existing.u) {
        // The slim participant is not here yet. This case is technically not required and adds some
        // overhead / redundancy but makes the system more resilient to for e.g. network errors.
        console.log('VL Pµ', p.id)
        participants_to_load[p.id] = true
      }
    },

    set_ws_visitors_fetching_queue(do_update) {
      if (do_update) console.info('QUEUE Visitors')
      this.ws_fetching_queue = do_update
    },

    set_visitors({ participants, monthly_summary, demis_fails, integration_data }) {
      const participants_to_load = {}
      participants.forEach(p => this.load_participant_if_needed(p, participants_to_load))
      this.monthly_summary = monthly_summary
      this.demis_fails = demis_fails
      this.participants_to_load = participants_to_load
      this.integration_data = integration_data
    },

    update_visitor(p) {
      Vue.set(this.participants_by_id, p.id, parse_participant(p))
    },

    set_visitors_processed_at({ participant_id, processed_at }) {
      const p = this.participants_by_id[participant_id]
      if (p) Vue.set(p, 'extra_fields', { ...(p.extra_fields || {}), processed_at })
    },

    set_search_results(results) {
      results.forEach(res => Vue.set(this.search_results, res.id, parse_participant(res)))
    },

    clear_visitors(new_day) {
      this.is_first_load = true
      this.current_day = new_day
      this.participants_by_id = {}
      this.monthly_summary = {}
      this.demis_fails = []
    },

    async fetch_visitor_qr_codes(participant_id) {
      const url = `${use_services().base_url_of_selected}/checkins/visitors/${participant_id}/qr-codes`
      const { data } = await Vue.smcb_axios.get(url)
      return data
    },

    async set_visitor_as_processed(participant_id) {
      const url = `${use_services().base_url_of_selected}/checkins/visitors/${participant_id}/processed`
      const { data } = await Vue.smcb_axios.post(url)
      const processed_at = data.processed_at
      this.set_visitors_processed_at({ participant_id, processed_at })
    },

    async get_days_visitors_export_data() {
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${use_services().base_url_of_selected}/checkins/visitors/${date_path}/export`
      const { data } = await Vue.smcb_axios.get(url)
      return data.map(v => parse_participant(v))
    },

    async save_time_slot_participant_check_out({ participant_id }) {
      const calculate_occupancy = use_gym_modules().headcount_calculate_occupancy

      const { data } = await Vue.smcb_axios.put(`${use_smcb_gym().base_url}/checkins/participants/${participant_id}/check-out`, {
        calculate_occupancy,
      })

      if (calculate_occupancy) {
        Vue.smcb_axios.post(`${use_entry_point().fred_base_url}/headcount/update`, { count: data.occupancy })
      }

      this.update_visitor(data.participant)
    },

    async export_days_visitors(payload) {
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${use_services().base_url_of_selected}/checkins/visitors/${date_path}/export`
      const { data } = await Vue.smcb_axios.post(url, payload)
      return data
    },

    async export_months_visitors(payload) {
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${use_services().base_url_of_selected}/checkins/visitors/${date_path}/export/month`
      const { data } = await Vue.smcb_axios.post(url, payload)
      return data
    },

    async export_date_range_visitors(payload) {
      const url = `${use_services().base_url_of_selected}/checkins/visitors/export/custom`
      const { data } = await Vue.smcb_axios.post(url, payload)
      return data
    },

    async fetch_days_visitors({ date }) {
      this.loading = true
      this.clear_visitors(date)
      await this.silently_fetch_days_visitors()
      this.loading = false
    },

    async silently_fetch_days_visitors() {
      const can_micro = !this.is_first_load
      this.is_first_load = false
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${use_services().base_url_of_selected}/checkins/visitors/${date_path}?micro=${can_micro}`
      const { data } = await Vue.smcb_axios.get(url)
      if (date_path === this.current_day.toFormat('yyyy/MM/dd')) this.set_visitors(data)
    },

    async silently_fetch_visitors_list({ participant_ids }) {
      const url = `${use_services().base_url_of_selected}/checkins/visitors/participants`
      const { data } = await Vue.smcb_axios.post(url, { ids: participant_ids })
      for (const participant of data.participants) {
        this.update_visitor(participant)
      }
    },

    async ws_restart_visitors_fetching() {
      if (this.ws_fetching_queue) {
        console.info('FETCH Visitors')
        this.silently_fetch_days_visitors()
        this.set_ws_visitors_fetching_queue(null)
      }
    },

    async search_visitors(query) {
      this.search_results = {}
      const url = `${use_services().base_url_of_selected}/checkins/visitors/search`
      const { data } = await Vue.smcb_axios.post(url, { query: query })
      this.set_search_results(data)
    },

    async select_visitor(visitor) {
      this.selected = null
      if (visitor) {
        this.selected = `${visitor.firstname} ${visitor.lastname}`
        await this.fetch_days_visitors({ date: visitor.checked_in_at })
      }
    },

    async trigger_demis_resubmission(payload) {
      const url = `${use_services().base_url_of_selected}/checkins/resubmit/demis`
      const { data } = await Vue.smcb_axios.post(url, payload)
      return data
    },

    async export_documents(payload) {
      payload.date = this.current_day
      const url = `${use_services().base_url_of_selected}/checkins/visitors/export-documents`
      const { data } = await Vue.smcb_axios.post(url, payload)
      return data
    },

    async export_muster_16(payload) {
      payload.date = this.current_day
      const url = `${use_services().base_url_of_selected}/checkins/visitors/export-documents`
      const { data } = await Vue.smcb_axios.post(url, payload)
      return data
    },

    async fetch_export_files() {
      const { data } = await Vue.smcb_axios.get(`${use_services().base_url_of_selected}/checkins/visitors/visitors-export-files`)
      this.export_files = data
    },

    async download_export_file(filename) {
      const { data } = await Vue.smcb_axios.get(`${use_services().base_url_of_selected}/checkins/visitors/visitors-export-download/${filename}`)
      return data.url
    },

    async delete_export_file(filename) {
      await Vue.smcb_axios.post(`${use_services().base_url_of_selected}/checkins/visitors/visitors-export-delete/${filename}`)
      var idx = this.export_files.indexOf(filename)
      if (idx !== -1) {
        this.export_files.splice(idx, 1)
      }
    },
  },

  getters: {
    get_current_days_visitors: state => {
      return Object.values(state.participants_by_id)
    },

    get_current_days_visitors_integration_data: state => {
      return state.integration_data || {}
    },

    get_visitor_search_results: state => {
      return Object.values(state.search_results)
    },
  },
})
