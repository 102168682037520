import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

import { use_absences } from '@core/entry_point/stores/absences_store'
import { use_actor } from '@core/entry_point/stores/actor_store'
import { use_agreements } from '@core/entry_point/stores/agreements_store'
import { use_business_days } from '@core/entry_point/stores/business_days_store'
import { use_business_units } from '@core/entry_point/stores/business_units_store'
import { use_calendar } from '@core/entry_point/stores/calendar_store'
import { use_calendar_customers } from '@core/entry_point/stores/calendar_customers_store'
import { use_calendar_employees } from '@core/entry_point/stores/calendar_employees_store'
import { use_calendar_filter } from '@core/entry_point/stores/calendar_filter_store'
import { use_calendar_setup } from '@core/entry_point/stores/calendar_setup_store'
import { use_calendar_slot_areas } from '@core/entry_point/stores/calendar_slot_areas_store'
import { use_capacity_overview } from '@core/entry_point/stores/capacity_overview_store'
import { use_companion_app } from '@core/entry_point/stores/companion_app_store'
import { use_dcc } from '@core/entry_point/stores/dcc_store'
import { use_demis } from '@core/entry_point/stores/demis_store'
import { use_devices } from '@core/entry_point/stores/devices_store'
import { use_email_templates } from '@core/entry_point/stores/email_templates_store'
import { use_employee_basic } from '@core/entry_point/stores/employee_basic_store'
import { use_employees } from '@core/entry_point/stores/employees_store'
import { use_employments } from '@core/entry_point/stores/employments_store'
import { use_entry_point } from '@core/entry_point/stores/entry_point_store'
import { use_fat_objects_for_modal } from '@core/entry_point/stores/fat_objects_for_modal_store'
import { use_gdpr_requests } from '@core/entry_point/stores/gdpr_requests_store'
import { use_gym_modules } from '@core/entry_point/stores/gym_modules_store'
import { use_heads_up } from '@core/entry_point/stores/heads_up_store'
import { use_hermes_messages } from '@core/entry_point/stores/hermes_messages_store'
import { use_human_is_idle } from '@core/entry_point/stores/human_is_idle_store'
import { use_invitations } from '@core/entry_point/stores/invitations_store'
import { use_map_locators } from '@core/entry_point/stores/map_locators_store'
import { use_overtime } from '@core/entry_point/stores/overtime_store'
import { use_participant_alt_keys } from '@core/entry_point/stores/participant_alt_keys_store'
import { use_participant_copypaste } from '@core/entry_point/stores/participant_copypaste_store'
import { use_participant_edit } from '@core/entry_point/stores/participant_edit_store'
import { use_pay_levels } from '@core/entry_point/stores/pay_levels_store'
import { use_payments } from '@core/entry_point/stores/payments_store'
import { use_pdf_editor } from '@core/entry_point/stores/pdf_editor_store'
import { use_permissions } from '@core/entry_point/stores/permissions_store'
import { use_popup_warnings } from '@core/entry_point/stores/popup_warnings_store'
import { use_popup_warnings_manager } from '@core/entry_point/stores/popup_warnings_manager_store'
import { use_prescriptions } from '@core/entry_point/stores/prescriptions_store'
import { use_pricings } from '@core/entry_point/stores/pricings_store'
import { use_private_urls } from '@core/entry_point/stores/private_urls_store'
import { use_public_holidays } from '@core/entry_point/stores/public_holidays_store'
import { use_quick_scanner } from '@core/entry_point/stores/quick_scanner_store'
import { use_recurring_tasks } from '@core/entry_point/stores/recurring_tasks_store'
import { use_rental } from '@core/entry_point/stores/rental_store'
import { use_reports } from '@core/entry_point/stores/reports_store'
import { use_reservations } from '@core/entry_point/stores/reservations_store'
import { use_reservations_current_booking } from '@core/entry_point/stores/reservations_current_booking_store'
import { use_resources } from '@core/entry_point/stores/resources_store'
import { use_rki } from '@core/entry_point/stores/rki_store'
import { use_service_documents } from '@core/entry_point/stores/service_documents_store'
import { use_services } from '@core/entry_point/stores/services_store'
import { use_services_stats } from '@core/entry_point/stores/services_stats_store'
import { use_shift_planning } from '@core/entry_point/stores/shift_planning_store'
import { use_sidemenu_counters } from '@core/entry_point/stores/sidemenu_counters_store'
import { use_sidemenu_rules } from '@core/entry_point/stores/sidemenu_rules_store'
import { use_signatures } from '@core/entry_point/stores/signatures_store'
import { use_slot_defs } from '@core/entry_point/stores/slot_defs_store'
import { use_smcb_gym } from '@core/entry_point/stores/smcb_gym_store'
import { use_smcb_gym_tags } from '@core/entry_point/stores/smcb_gym_tags_store'
import { use_staff_finance } from '@core/entry_point/stores/staff_finance_store'
import { use_staff_users } from '@core/entry_point/stores/staff_users_store'
import { use_task_bookings } from '@core/entry_point/stores/task_bookings_store'
import { use_task_groups } from '@core/entry_point/stores/task_groups_store'
import { use_tasks } from '@core/entry_point/stores/tasks_store'
import { use_tickets } from '@core/entry_point/stores/tickets_store'
import { use_vax_overview } from '@core/entry_point/stores/vax_overview_store'
import { use_video_consultation } from '@core/entry_point/stores/video_consultation_store'
import { use_visitors } from '@core/entry_point/stores/visitors_store'
import { use_work_categories } from '@core/entry_point/stores/work_categories_store'
import { use_work_logs } from '@core/entry_point/stores/work_logs_store'
import { use_working_hours } from '@core/entry_point/stores/working_hours_store'

export { PiniaVuePlugin }

export async function create_pinia(extra_uses) {
  if (!window.vl_pinia_store) {
    console.info('VL creating new pinia store')
    window.vl_pinia_store = await createPinia()
    window.vl_use_all = await load_all(extra_uses)
  } else {
    while (!window.vl_use_all) {
      await Vue.$vl_utils.delay(10)
    }

    if (extra_uses) {
      const these = await load_these(extra_uses)
      window.vl_use_all = { ...window.vl_use_all, ...these }
    }

    console.info('VL reusing existing pinia store')
  }
  return window.vl_pinia_store
}

async function load_these(uses) {
  const use = {}
  Object.entries(uses).forEach(([module_key, f]) => {
    const name = module_key.split('use_')[1]
    // console.info('loading:', name)
    use[name] = f(window.vl_pinia_store)
  })

  return use
}

async function load_all(extra_uses) {
  return await load_these({
    use_absences,
    use_actor,
    use_agreements,
    use_business_days,
    use_business_units,
    use_calendar,
    use_calendar_customers,
    use_calendar_employees,
    use_calendar_filter,
    use_calendar_setup,
    use_calendar_slot_areas,
    use_capacity_overview,
    use_companion_app,
    use_dcc,
    use_demis,
    use_devices,
    use_email_templates,
    use_employee_basic,
    use_employees,
    use_employments,
    use_entry_point,
    use_fat_objects_for_modal,
    use_gdpr_requests,
    use_gym_modules,
    use_heads_up,
    use_hermes_messages,
    use_human_is_idle,
    use_invitations,
    use_map_locators,
    use_overtime,
    use_participant_alt_keys,
    use_participant_copypaste,
    use_participant_edit,
    use_pay_levels,
    use_payments,
    use_pdf_editor,
    use_permissions,
    use_popup_warnings_manager,
    use_popup_warnings,
    use_prescriptions,
    use_pricings,
    use_private_urls,
    use_public_holidays,
    use_quick_scanner,
    use_recurring_tasks,
    use_rental,
    use_reports,
    use_reservations_current_booking,
    use_reservations,
    use_resources,
    use_rki,
    use_service_documents,
    use_services_stats,
    use_services,
    use_shift_planning,
    use_sidemenu_counters,
    use_sidemenu_rules,
    use_signatures,
    use_slot_defs,
    use_smcb_gym_tags,
    use_smcb_gym,
    use_staff_finance,
    use_staff_users,
    use_task_bookings,
    use_task_groups,
    use_tasks,
    use_tickets,
    use_vax_overview,
    use_video_consultation,
    use_visitors,
    use_work_categories,
    use_work_logs,
    use_working_hours,
    ...extra_uses,
  })
}
