import { defineStore } from 'pinia'

export const use_calendar_setup = defineStore('calendar_setup', {
  state: () => ({
    new_work_category: false,
    work_category_to_edit: {},
    new_business_unit: false,
    business_unit_to_edit: {},
    local_keys: {
      active_business_unit_courses: 'calendar_setup_active_business_unit_id_on_courses',
      active_business_unit_tasks: 'calendar_setup_active_business_unit_id_on_tasks',
    },
  }),

  actions: {
    add_work_category(work_category) {
      this.new_work_category = true
      this.work_category_to_edit = work_category
    },

    edit_work_category(work_category) {
      this.new_work_category = false
      this.work_category_to_edit = work_category
    },

    add_business_unit() {
      this.new_business_unit = true
      this.business_unit_to_edit = {}
    },

    edit_business_unit(business_unit) {
      this.new_business_unit = false
      this.business_unit_to_edit = business_unit
    },

    load_active_business_unit_id(context) {
      return parseInt(localStorage.getItem(this._local_bu_key(context))) || null
    },

    save_active_business_unit_id(context, business_unit_id) {
      localStorage.setItem(this._local_bu_key(context), business_unit_id)
    },

    // Internal methods

    _local_bu_key(context) {
      return this.local_keys[`active_business_unit_${context}`]
    },
  },
})
