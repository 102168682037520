import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'
import editable_mixin from './editable_mixin'

function parse_email_template(email_template) {
  return Object.freeze(email_template)
}

function hash_email_template(obj) {
  const sorted_keys = Object.keys(obj)
    .filter(key => {
      const [type, locale] = key.split('_')
      return !((type === 'body' || type === 'subject') && !obj.translations.includes(locale) && (obj[key] === null || obj[key] === ''))
    })
    .sort()

  const str = JSON.stringify(obj, sorted_keys)
  let hash = 0,
    i,
    chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32-bit integer
  }

  return hash
}

export const use_email_templates = defineStore('email_templates_store', {
  state: () => ({
    ...editable_mixin.state(),
    hashed_email_template_edit: '',
    task_group_ids: [],
    all: [],
  }),

  actions: {
    ...editable_mixin.actions(),

    async load_task_group_email_templates(task_group_id) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/taskgroups/${task_group_id}/email_templates`)
      return data
    },

    async load_email_templates() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/email_templates`)
      this.all = data.map(parse_email_template)
      return data
    },

    async load_email_communications() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/email_communications`)
      return data
    },

    async load_and_edit_email_template(email_template_id) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/email_templates/${email_template_id}`)
      this.update_edit(data)
      this.hashed_email_template_edit = hash_email_template(this.edit)
      this.task_group_ids = [...this.edit.task_group_ids]
      return data
    },

    async load_available_course_templates() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/email_templates/task_groups`)
      return data
    },

    async create_email_template(email_template, task_group_id = null) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/email_templates`, { ...(task_group_id && { task_group_id }), ...email_template })
      this.all = [...this.all, parse_email_template(data)]
      return data
    },

    async update_email_template(email_template) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url}/email_templates/${email_template.id}`, { email_template })
      const local_email_template = { ...data }
      this.write_email_template(local_email_template)
      this.hashed_email_template_edit = hash_email_template(this.edit)
      this.task_group_ids = [...this.edit.task_group_ids]
      return data
    },

    async unarchive_email_template(email_template_id) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url}/email_templates/${email_template_id}`, { archived_at: null })
      const local_email_template = { ...data }
      this.write_email_template(local_email_template)
      return data
    },

    async archive_email_template(email_template_id) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/email_templates/${email_template_id}/archive`)
      const local_email_template = { ...data }
      this.write_email_template(local_email_template)
      return data
    },

    async unassign_email_template(task_group_id, email_template_id) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/taskgroups/${task_group_id}/email_templates/${email_template_id}/unassign`)
      return data
    },

    async assign_email_template(task_group_id, email_template_id) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/taskgroups/${task_group_id}/email_templates/${email_template_id}/assign`)
      return data
    },

    async send_email_now(parameters) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/email_templates/send_test_email`, { ...parameters })
      return data
    },

    write_email_template(email_template) {
      const index = this.all.findIndex(et => et.id === email_template.id)
      Vue.set(this.all, index, parse_email_template(email_template))
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}`,
    active: state => state.all.filter(et => !et.archived_at),
    archived: state => state.all.filter(et => et.archived_at),
    email_template_from_id: state => id => state.all.find(t => t.id === id),
    email_template_edit_changed: state => state.hashed_email_template_edit !== hash_email_template(state.edit),
    original_task_group_ids: state => state.task_group_ids,
    merge_tags: state => [
      {
        value: 'firstname',
        text: Vue.$i18n.t('calendar.courses.merge_tags.firstname'),
      },
      {
        value: 'lastname',
        text: Vue.$i18n.t('calendar.courses.merge_tags.lastname'),
      },
      {
        value: 'course_name',
        text: Vue.$i18n.t('calendar.courses.merge_tags.course_name'),
      },
      {
        value: 'course_start_date',
        text: Vue.$i18n.t('calendar.courses.merge_tags.course_start_date'),
      },
      {
        value: 'course_start_time',
        text: Vue.$i18n.t('calendar.courses.merge_tags.course_start_time'),
      },
    ],
  },
})
